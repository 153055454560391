import { authenticationService } from "../src/authorize/_services";
import { MasterDataService } from "../src/assets/commonMasterData";
//const env = require("../public/buildVersion.json");
export const currentEnv = require("../package.json").environment;
/*current env name should be----> local,test,uat,dev,mhctest,sandbox-----*/
export const currentEnv1 = 'LOCAL'

//"http://localhost:6007/API"

export const WorkListAPI = process.env[`REACT_APP_WorkListAPI_${currentEnv.toUpperCase()}`]
export const CoreMasterAPI = process.env[`REACT_APP_CoreMasterAPI_${currentEnv.toUpperCase()}`]
export const CoreBaseAPI = process.env[`REACT_APP_CoreBaseAPI_${currentEnv.toUpperCase()}`]
export const CoreAppointmentAPI = process.env[`REACT_APP_CoreAppointmentAPI_${currentEnv.toUpperCase()}`]
export const CoreInventoryAPI = process.env[`REACT_APP_CoreInventoryAPI_${currentEnv.toUpperCase()}`]
export const CoreInventoryInternalAPI = process.env[`REACT_APP_CoreInventoryAPI_${currentEnv.toUpperCase()}`]
//export const CoreInventoryInternalAPI = process.env[`REACT_APP_CoreInventoryInternalAPI_${currentEnv.toUpperCase()}`]
export const CoreInventoryReportAPI = process.env[`REACT_APP_CoreInventoryReportAPI_${currentEnv.toUpperCase()}`]
export const CoreReportAPI = process.env[`REACT_APP_CoreReportAPI_${currentEnv.toUpperCase()}`]
export const SIGNALR_NOTIFICATION_URL = process.env[`REACT_APP_SIGNALR_NOTIFICATION_URL_${currentEnv.toUpperCase()}`]
export const REACT_APP_HOST = process.env[`REACT_APP_REACT_APP_HOST_${currentEnv.toUpperCase()}`]
export const REACT_APP_BASE_URL = process.env[`REACT_APP_REACT_APP_BASE_URL_${currentEnv.toUpperCase()}`]
export const REACT_APP_BASE_URL_IPD = process.env[`REACT_APP_REACT_APP_BASE_URL_IPD_${currentEnv.toUpperCase()}`]
export const REACT_APP_URL_EMR_EXTERNAL = process.env[`REACT_APP_REACT_APP_URL_EMR_EXTERNAL_${currentEnv.toUpperCase()}`]
export const REACT_APP_URL_EMR_EXTERNAL_FETCH = process.env[`REACT_APP_REACT_APP_URL_EMR_EXTERNAL_FETCH_${currentEnv.toUpperCase()}`]
export const OPD_APP_HOST = process.env[`REACT_APP_OPD_APP_HOST_${currentEnv.toUpperCase()}`]
export const IntegrationAPI = process.env[`REACT_APP_INT_APP_HOST_${currentEnv.toUpperCase()}`]
export const QmsAddress = process.env[`REACT_APP_QMS_${currentEnv.toUpperCase()}`];
export const SolrAPI = process.env[`REACT_APP_SolrAPI_${currentEnv.toUpperCase()}`] + currentEnv.toUpperCase();
export const LisBaseUrl = process.env[`REACT_APP_MHC_LIS_${currentEnv.toUpperCase()}`];
export const QmsAddressNEW = process.env[`REACT_APP_QMSNEW_${currentEnv.toUpperCase()}`];
export const FormBuilderAPI = process.env[`REACT_APP_FormBuilderAPI_${currentEnv.toUpperCase()}`];
export const PrintAPI = process.env[`REACT_APP_PrintAPI_${currentEnv.toUpperCase()}`];
export const CorePaymentAPI = process.env[`REACT_APP_CorePaymentAPI_${currentEnv.toUpperCase()}`]
export const Homecare = process.env[`REACT_APP_Homecare_PRODUCTION${currentEnv.toUpperCase()}`]
export const clientEnvironment = process.env[`REACT_APP_CLIENT_${currentEnv.toUpperCase()}`];

// STATIC INFORMATIONS FOR DEMOe
export const SHOW_STATIC_INVESTIGATION_RESULT = 'Y';



// Homecare Data
//export const HC_API_URL 		= currentEnv.toUpperCase() == "RFHPROD" ? 'mhea-hpamicro.rfhospital.org/' : "https://mhea-hpmicro.rfhospital.org/"

export const HC_API_URL 		= process.env[`REACT_APP_HC_API_URL_${currentEnv.toUpperCase()}`]

export const HC_PINCODE_URL 	= currentEnv.toUpperCase() == "RFHPROD" ? 'https://mhea-hpapi.rfhospital.org/' : "https://ppamhea-hpapi.rfhospital.org/"
export const SAIL_BACKEND_SPA 	= currentEnv.toUpperCase() == "RFHPROD" ? 'https://mhea-spa.rfhospital.org/' : "https://ppamhea-spa.rfhospital.org/"
export const API_BASE_URL_EMR 	= currentEnv.toUpperCase() == "RFHPROD" ? 'https://emr.rfhospital.org/' : "https://ppaemr.rfhospital.org/"
export const ADMIN_PATIENT_LINK = currentEnv.toUpperCase() == "RFHPROD" ? 'https://mhea-hp.rfhospital.org/' : `https://ppamhea-hp.rfhospital.org/`

export const API_BASE_URL 		= `${HC_API_URL}api/homecare/`;   

export const EMR_API_TOKEN 	= "OzxOcSjaMtXpuqEGJhsPDx8UDW8Is0v6oQKqyTobaaouoTYWYgzsExc7h8EzYZva"
export const S3_BASE_URL 	= "https://qb-innocirc-backups.s3.ap-south-1.amazonaws.com/"

export const HC_AGGREGATOR_TYPE = "opd_emr"
export const HC_API_TOKEN = "eyJpdiI6ImxUeXIra0VIMDdrb1hSY1F3Z2UyXC9nPT0iLCJ2YWx1ZSI6Imh1TmZDWWdLRTh2cVBVTHdrR3A4dkE9PSIsIm1hYyI6IjdjNzAxMTY4OTRkNGExYmMzN2U5N2VmZDZlNTQwY2I5YmNjYjU2ZTAwYThhZjBkNzk5YWMwM2Y1YTJiZGM3MjgifQ=="

export const X_API_TOKEN = "eyJdbnmhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IasdjoxNTE2MjM5MDIyfQ.k-xG4UDuyZpekRdy8wAY-j6jhgf0h4JG4DgeShhSgiKhrZE"

/*------------------ all teams constants start ------------------*/
export const HC_TEAM_UPDATE_API_URL = `${API_BASE_URL}team-update`
export const HC_TEAM_DELETE_API_URL = `${API_BASE_URL}team-delete`
export const HC_TEAM_EDIT_API_URL = `${API_BASE_URL}team-edit`
export const HC_TEAM_SAVE_API_URL = `${API_BASE_URL}team-store`
export const HC_TEAM_LIST_API_URL = `${API_BASE_URL}team-list`
export const HC_TEAM_LIST_REQUEST = "HC_TEAM_LIST_REQUEST"
export const HC_TEAM_LIST_SUCCESS = "HC_TEAM_LIST_SUCCESS"
export const HC_TEAM_LIST_FILTER = "HC_TEAM_LIST_FILTER"
export const HC_TEAM_LIST_FAIL = "HC_TEAM_LIST_FAIL"
/*------------------ all teams constants end   ------------------*/


/*------------------ all extended hospital location start   ------------------*/
export const HC_HOSPITAL_LOCATION_LIST_API_URL = `${API_BASE_URL}hospital-locations-list`
export const HC_HOSPITAL_LOCATION_SAVE_API_URL = `${API_BASE_URL}store-extended-hospital-locations`
export const HC_HOSPITAL_LOCATION_EDIT_API_URL = `${API_BASE_URL}hospital-location-edit`
export const HC_HOSPITAL_LOCATION_UPDATE_API_URL = `${API_BASE_URL}update-hospital-location`
export const HC_HOSPITAL_LOCATION_LIST_REQUEST = "HC_HOSPITAL_LOCATION_LIST_REQUEST"
export const HC_HOSPITAL_LOCATION_LIST_SUCCESS = "HC_HOSPITAL_LOCATION_LIST_SUCCESS"
export const HC_HOSPITAL_LOCATION_LIST_FILTER = "HC_HOSPITAL_LOCATION_LIST_FILTER"
export const HC_HOSPITAL_LOCATION_LIST_FAIL = "HC_HOSPITAL_LOCATION_LIST_FAIL"
/*------------------ all extended hospital location end   ------------------*/


/*------------------ all team resource start   ------------------*/
export const HC_TEAM_RESOURCE_CAT_LIST_API_URL = `${API_BASE_URL}get-resource-name-list`
export const HC_TEAM_RESOURCE_LIST_API_URL = `${API_BASE_URL}homecare-resources-list`
export const HC_TEAM_RESOURCE_LIST_REQUEST = "HC_TEAM_RESOURCE_LIST_REQUEST"
export const HC_TEAM_RESOURCE_LIST_SUCCESS = "HC_TEAM_RESOURCE_LIST_SUCCESS"
export const HC_TEAM_RESOURCE_LIST_FILTER = "HC_TEAM_RESOURCE_LIST_FILTER"
export const HC_TEAM_RESOURCE_LIST_FAIL = "HC_TEAM_RESOURCE_LIST_FAIL"
/*------------------ all team resource end   ------------------*/


/*------------------ all sub category start   ------------------*/
export const HC_SUB_CATEGORY_LIST_API_URL = `${API_BASE_URL}subcategory-list`
export const HC_SUB_CATEGORY_LIST_REQUEST = "HC_SUB_CATEGORY_LIST_REQUEST"
export const HC_SUB_CATEGORY_LIST_SUCCESS = "HC_SUB_CATEGORY_LIST_SUCCESS"
export const HC_SUB_CATEGORY_LIST_FILTER = "HC_SUB_CATEGORY_LIST_FILTER"
export const HC_SUB_CATEGORY_LIST_FAIL = "HC_SUB_CATEGORY_LIST_FAIL"
/*------------------ all sub category end   ------------------*/

/*------------------ all service & routing mapping start   ------------------*/
export const HC_ROUTE_MAPING_LIST_API_URL = `${API_BASE_URL}route-list?search=`
export const HC_ROUTE_MAPING_SAVE_API_URL = `${API_BASE_URL}store-route`
export const HC_ROUTE_MAPING_EDIT_API_URL = `${API_BASE_URL}get-route`
export const HC_ROUTE_MAPING_UPDATE_API_URL = `${API_BASE_URL}update-route`
/*------------------ all service & routing mapping end   ------------------*/

/*-------------------------- all country start   --------------------------*/
export const HC_COUNTRY_LIST_API_URL = `${API_BASE_URL}country-list`
export const HC_COUNTRY_LIST_REQUEST = "HC_COUNTRY_LIST_REQUEST"
export const HC_COUNTRY_LIST_SUCCESS = "HC_COUNTRY_LIST_SUCCESS"
export const HC_COUNTRY_LIST_FILTER = "HC_COUNTRY_LIST_FILTER"
export const HC_COUNTRY_LIST_FAIL = "HC_COUNTRY_LIST_FAIL"
/*-------------------------- all country end   --------------------------*/

/*-------------------------- all state start   --------------------------*/
export const HC_STATE_LIST_API_URL = `${API_BASE_URL}state-list`
export const HC_STATE_LIST_REQUEST = "HC_STATE_LIST_REQUEST"
export const HC_STATE_LIST_SUCCESS = "HC_STATE_LIST_SUCCESS"
export const HC_STATE_LIST_FILTER = "HC_STATE_LIST_FILTER"
export const HC_STATE_LIST_FAIL = "HC_STATE_LIST_FAIL"
/*-------------------------- all state end   --------------------------*/

/*-------------------------- all city start   --------------------------*/
export const HC_CITY_LIST_API_URL = `${API_BASE_URL}city-list`
export const HC_CITY_LIST_REQUEST = "HC_CITY_LIST_REQUEST"
export const HC_CITY_LIST_SUCCESS = "HC_CITY_LIST_SUCCESS"
export const HC_CITY_LIST_FILTER = "HC_CITY_LIST_FILTER"
export const HC_CITY_LIST_FAIL = "HC_CITY_LIST_FAIL"
/*-------------------------- all city end   --------------------------*/

/*-------------------------- all pincode sta -------------------------*/
export const HC_PINCODE_LIST_API_URL = `${API_BASE_URL}pincode-list`
export const HC_PINCODE_LIST_REQUEST = "HC_PINCODE_LIST_REQUEST"
export const HC_PINCODE_LIST_SUCCESS = "HC_PINCODE_LIST_SUCCESS"
export const HC_PINCODE_LIST_FILTER = "HC_PINCODE_LIST_FILTER"
export const HC_PINCODE_LIST_FAIL = "HC_PINCODE_LIST_FAIL"
/*-------------------------- all pincode end--------------------------*/

/*-------------------------- hospital start --------------------------*/
export const HC_HOSPITAL_LIST_API_URL = `${API_BASE_URL}hospital-list`
export const HC_HOSPITAL_LIST_REQUEST = "HC_HOSPITAL_LIST_REQUEST"
export const HC_HOSPITAL_LIST_SUCCESS = "HC_HOSPITAL_LIST_SUCCESS"
export const HC_HOSPITAL_LIST_FILTER = "HC_HOSPITAL_LIST_FILTER"
export const HC_HOSPITAL_LIST_FAIL = "HC_HOSPITAL_LIST_FAIL"
/*-------------------------- hospital end  ---------------------------*/

/*-------------------------- category end  ---------------------------*/
export const HC_CATEGORY_LIST_API_URL = `${API_BASE_URL}category-list`
export const HC_CATEGORY_LIST_REQUEST = "HC_CATEGORY_LIST_REQUEST"
export const HC_CATEGORY_LIST_SUCCESS = "HC_CATEGORY_LIST_SUCCESS"
export const HC_CATEGORY_LIST_FILTER = "HC_CATEGORY_LIST_FILTER"
export const HC_CATEGORY_LIST_FAIL = "HC_CATEGORY_LIST_FAIL"
export const HC_ROUTE_MAPING_GET_SINGLE_CATEGORY = `${API_BASE_URL}get-category`
export const HC_UPDATE_CATEGORY = `${API_BASE_URL}update-category`
export const HC_STORE_CATEGORY = `${API_BASE_URL}store-category`
export const HC_TREATMENT_OU_API_URL = `${API_BASE_URL}homecare-treatment-ou-list`
export const HC_DEPARTMENT_OU_API_URL = `${API_BASE_URL}homecare-department-ou-list`
/*-------------------------- category end  ---------------------------*/

export const HC_SERVICE_CODE_API_URL = `${API_BASE_URL}homecare-service-code-list`
export const HC_CLINICAL_EVALUATION_EDIT_API = `${API_BASE_URL}booking/clinical-evaluation-edit`

/*********************************** Home Care Booking API's START ******************/

export const HC_LOGIN 					= `${HC_API_URL}api/login`
export const HC_AUTOLOGIN 				= `${HC_API_URL}api/auto-login`
export const HC_LOGOUT 					= `${HC_API_URL}api/logout`
export const HC_BOOKING_LIST 			= `${HC_API_URL}api/homecare/manage-homecare-bookings`
export const HC_ROUTE_LIST 				= `${HC_API_URL}api/homecare/route-list`
export const HC_CATEGORY_LIST 			= `${HC_API_URL}api/homecare/route-list`
export const HC_RESOURCE_LIST 			= `${HC_API_URL}api/homecare/homecare-resources-list`
export const HC_PAY_LATER 				= `${HC_API_URL}api/homecare/pay-later`
export const HC_MULTIPLE_PAY_LATER 		= `${HC_API_URL}api/homecare/pay-later-multiple`
export const HC_PAY_SEND_LINK 			= `${HC_API_URL}api/homecare/send-payment-link`
export const HC_SAVE_RESOURCE_ASSIGNED 	= `${HC_API_URL}api/homecare/booking/save-resource-assigned`
export const SAVE_BULK_ASSIGNED 		= `${HC_API_URL}api/homecare/booking/booking-bulk-resource-assigned`
export const HC_SAVE_CLINICAL_EVAL 		= `${HC_API_URL}api/homecare/booking/save-clinical-evaluation`
export const HC_GET_ASSIGNED_DATA 		= `${HC_API_URL}api/homecare/booking/get-assigned-data`
export const HC_SAVE_RESOURCE_ASSIGNED_UPDATE = `${HC_API_URL}api/homecare/booking/resource-assigned-update`
export const HC_CARE_PLAN_DATA 			= `${HC_API_URL}api/homecare/get-homecare-booking-care-plan`
export const HC_CARE_PLAN_UPDATE 		= `${HC_API_URL}api/homecare/get-homecare-booking-care-plan-update`;
export const HC_CARE_PLAN_DETAILS 		= `${HC_API_URL}api/homecare/get-homecare-booking-care-plan-edit`
export const HC_TEAM_LIST 				= `${HC_API_URL}api/homecare/team-list`
export const CANCEL_HOMECARE_PLAN 		= `${HC_API_URL}api/homecare/cancel-homecare-booking`
export const UPLOAD_HOMECARE_DOCUMENT 	= `${SAIL_BACKEND_SPA}api/v1/spa/upload-booking-document`
export const GET_ARRAY_BUFFER_FORM_URL 	= `${SAIL_BACKEND_SPA}api/v1/spa/file-Buffer`
export const GET_HOMECARE_DOCUMENT 		= `${SAIL_BACKEND_SPA}api/v1/spa/get-booking-document`
export const uploadCreditLetter 		= `${SAIL_BACKEND_SPA}api/v1/spa/upload-credit-letter-document`
export const getCreditDocument 			= `${SAIL_BACKEND_SPA}api/v1/spa/get-credit-letter-document`
export const HC_PINCODE_STATE_CITY 		= `${HC_PINCODE_URL}api/patients/ios/v2/getdata-based-on-pincode`
export const HC_DOCTOR_LIST 			= `${HC_API_URL}api/homecare/booking/get-doctor-list`
export const HC_VISIT_TYPE_LIST 		= `${HC_API_URL}api/homecare/booking/get-visit-type`
export const HC_ORDER_MASTER_LIST 		= `${HC_API_URL}api/homecare/booking/get-order-master`
export const HC_DEPARTMENT_LIST 		= `${HC_API_URL}api/homecare/booking/get-department-ou`
export const HC_CHECK_RESOURCE_TEAM_ASSIGNABILITY = `${HC_API_URL}api/homecare/booking/check-resource-team-assignability`
export const HC_CHECK_PAYMENT_OPTIONS 	= `${HC_API_URL}api/homecare/get-all-payment-option`

export const TIME_SLOTS = [
	{ "time_slot": "09:00", "timeStr": "09:00 AM" },
	{ "time_slot": "10:00", "timeStr": "10:00 AM" },
	{ "time_slot": "11:00", "timeStr": "11:00 AM" },
	{ "time_slot": "12:00", "timeStr": "12:00 AM" },
	{ "time_slot": "13:00", "timeStr": "01:00 PM" },
	{ "time_slot": "14:00", "timeStr": "02:00 PM" },
	{ "time_slot": "15:00", "timeStr": "03:00 PM" },
	{ "time_slot": "16:00", "timeStr": "04:00 PM" },
	{ "time_slot": "17:00", "timeStr": "05:00 PM" },
	{ "time_slot": "18:00", "timeStr": "06:00 PM" },
	{ "time_slot": "19:00", "timeStr": "07:00 PM" },
	{ "time_slot": "20:00", "timeStr": "08:00 PM" },
	{ "time_slot": "21:00", "timeStr": "09:00 PM" },
]

export const PAYMENT_MODE = ['jiomoney', 'payu', 'cash', 'hdfc', 'vPay', 'not_applicable', 'cash_at_hospital', 'card', 'advance', 'upi', 'netbanking', 'other', 'pay_on_arrival', 'razorpay', 'credit', 'jiomoney', 'credit_not_approved', 'payment_pending', 'paid_at_hospital', 'pay_later', 'unknown'];

/*********************************** Home Care Booking API's END ******************/

/*********************************** Home Manage Resources API's START ******************/
export const HC_MANAGE_RESOURCES_START 				= `${HC_API_URL}api/homecare/homecare-resources-list`
export const HC_MANAGE_RESOURCES_ADD 				= `${HC_API_URL}api/homecare/store-homecare-resources`
export const HC_MANAGE_RESOURCES_UPDATE 			= `${HC_API_URL}api/homecare/homecare-resources-update`
export const HC_MANAGE_RESOURCES_FETCH_BY_ID 		= `${HC_API_URL}api/homecare/homecare-resources-edit`
export const HC_MANAGE_RESOURCES_ATTENDANCE_FETCH 	= `${HC_API_URL}api/homecare-attendance-details`;
export const HC_MANAGE_RESOURCES_ATTENDANCE_TRACK 	= `${HC_API_URL}api/homecare-attendance-track-details`;
export const HC_MANAGE_RESOURCES_ATTENDANCE 		= `${HC_API_URL}api/homecare-attendance-summary-details`;
export const HC_MANAGE_RESOURCES_ATTENDANCE_V2 		= `${HC_API_URL}api/homecare-attendancereport-details`;
export const HC_MANAGE_RESOURCES_LEAVE_APPLY 		= `${HC_API_URL}api/homecare-leave-apply`;
export const HC_MANAGE_RESOURCES_CHECK_AVAILABILTY 	= `${HC_API_URL}api/homecare-check-availability`;
export const HC_MANAGE_RESOURCES_ASSIGN_RESOURCE 	= `${HC_API_URL}api/homecare-check-availability`;
export const HC_ORDER_CARE_PLAN_DETAILS 			= `${HC_API_URL}api/homecare/get-homecare-booking-care-plan-history`;
export const HC_SCHEDULER_BULK_UPLOAD 				= `${HC_API_URL}api/homecare/scheduler-upload-resources`;


/*********************************** Home Manage Resources API's END ******************/
/*----- Add Category---*/
export const HomeCareAdd_CATEGORY_REQUEST 	= "HomeCareAdd_CATEGORY_REQUEST"
export const HomeCareAdd_CATEGORY_SUCCESS 	= "HomeCareAdd_CATEGORY_SUCCESS"
export const HomeCareAdd_CATEGORY_FAIL 		= "HomeCareAdd_CATEGORY_FAIL"

/*----- Update Category---*/
export const HomeCareUpdate_CATEGORY_REQUEST = "HomeCareUpdate_CATEGORY_REQUEST"
export const HomeCareUpdate_CATEGORY_SUCCESS = "HomeCareUpdate_CATEGORY_SUCCESS"
export const HomeCareUpdate_CATEGORY_FAIL 	 = "HomeCareUpdate_CATEGORY_FAIL"


export const HOSPITAL_LIST 				= "hospital-list";
export const CATEGORIES_LIST 			= "category-list";
export const HOSPITAL_LOCATIONS_LIST 	= "hospital-locations-list";
export const SUB_CATEGORY_LIST 			= "subcategory-list";
export const SAVE_HOMECARE_SERVICE 		= "store-homecare-service";
export const SAVE_HOMECARE_PACKAGE 		= "store-homecare-package";
export const UPDATE_HOMECARE_SERVICE 	= "homecare-service-update";
export const UPDATE_HOMECARE_PACKAGE 	= "homecare-package-update";
export const HOMECARE_SERVICE_LIST 		= `${HC_API_URL}api/homecare/homecare-service-list`;
export const HOMECARE_CHECK_PLAN 		= "check-homecare-plan";
export const VITALS_EMR_MASTER 			= `${API_BASE_URL_EMR}api/opdemr/patient-app/vitalgroup/list`;

export const schedulelistApi 			= `${HC_API_URL}api/homecare/scheduler-list`
export const categoriesListApi 			= `${HC_API_URL}api/homecare/category-list`
export const subCategoriesApi 			= `${HC_API_URL}api/homecare/get-category`
export const resourcesList 				= `${HC_API_URL}api/homecare/homecare-resources-list`
export const routeList 					= `${HC_API_URL}api/homecare/route-list`
export const storeScheduler 			= `${HC_API_URL}api/homecare/store-scheduler`
export const checkScheduler 			= `${HC_API_URL}api/homecare/check-scheduler`
export const schedulerEdit 				= `${HC_API_URL}api/homecare/scheduler-edit`
export const schedulerDetailsByResourceId = `${HC_API_URL}api/homecare/scheduler-edit-resource-id`
export const schedulerUpdate 			= `${HC_API_URL}api/homecare/scheduler-update`
export const getCareplanList 			= `${HC_API_URL}api/homecare/homecare-plan-list`
export const getHospitalList 			= `${HC_API_URL}api/homecare/hospital-list`
export const taskFrequencyList 			= `${HC_API_URL}api/homecare/get-task-frequency-list`
export const homecareServiceList 		= `${HC_API_URL}api/homecare/homecare-service-package-list`
export const saveHomeCarePlan 			= `${HC_API_URL}api/homecare/save-homecare-plan`
export const updateHomeCarePlan 		= `${HC_API_URL}api/homecare/update-homecare-plan`
export const getHomeCarePlan 			= `${HC_API_URL}api/homecare/get-homecare-plan`
export const hospitalLocationApi 		= `${HC_API_URL}api/homecare/hospital-locations-list`
export const storeLeave 				= `${HC_API_URL}api/homecare/store-leave`
export const leaveList 					= `${HC_API_URL}api/homecare/leave-list`
export const leaveDelete 				= `${HC_API_URL}api/homecare/leave-delete`
export const leaveEdit 					= `${HC_API_URL}api/homecare/leave-edit`
export const updateLeave 				= `${HC_API_URL}api/homecare/leave-update`

//******************Homecare Packages related  */
export const saveHomeCareService 		= `${HC_API_URL}api/homecare/store-homecare-service`
export const getPacakageList 			= `${HC_API_URL}api/homecare/homecare-service-package-list`

/*----- homecare resourece api---*/
export const homecareTaskList 			= `${HC_API_URL}api/homecare-task-details`
export const rejectedBookingList 		= `${HC_API_URL}api/homecare-rejected-booking`

/*------- homecare reasonmaster---------*/
export const reasonmasterList 			= `${HC_API_URL}api/homecare/get-reason-masters`
export const storeReasonMaster 			= `${HC_API_URL}api/homecare/create-reason-master`
export const updateReasonMaster 		=`${HC_API_URL}api/homecare/update-reason-master`
export const deleteReasonMaster 		=`${HC_API_URL}api/homecare/delete-reason-master`

export const saveSkipOTP 				= `${HC_API_URL}api/homecare/create-skip-otp-reason`
export const exportMISReport 			= `${HC_API_URL}api/homecare/mis-report`
export const createBill 				= `${HC_API_URL}api/homecare/create-bill`
export const settleBill 				= `${HC_API_URL}api/homecare/settle-bill`
export const getPayerList 				= `${HC_API_URL}api/homecare/homecare-payer-master`
export const serviceCodeList			= `${HC_API_URL}api/homecare/get-service-code`
export const noVistCharge				= `${HC_API_URL}api/homecare/no-visit-charge`

export const saveHomecareAppt			= `${HC_API_URL}api/app/homecare/multihomecare-request-patient`

export const LOADER_RED = (
	<div className="spinner red">
		<div className="bounce1"></div>
		<div className="bounce2"></div>
		<div className="bounce3"></div>
	</div>
);

export const LOADER_BLACK = (
	<div className="spinner black">
	  <div className="bounce1"></div>
	  <div className="bounce2"></div>
	  <div className="bounce3"></div>
	</div>
);

export const SAKRA_HIS_CATEGORIES_LIST = "sakra-his-categories";
export const findNurseByMobile = `${HC_API_URL}api/homecare/find-nurse-by-mobile`
export const leaveDeleteURL = `${HC_API_URL}api/homecare/leave-delete`
export const UPDATE_BULK_ASSIGNED = `${HC_API_URL}api/homecare/booking/booking-bulk-resource-assigned`
export const SEND_MULTIPAY_LINK = `${HC_API_URL}api/homecare/send-multi-payment-link`
export const HC_DOCTOR_LIST_BY_HOSPITAL = `${HC_API_URL}api/homecare/booking/get-doctor-list-by-hospital-id`
export const HC_BOOKING_GENERATE_INVOICE = `${HC_API_URL}api/homecare/generate-booking-invoice`;

// export const LOADER = (
// 	<div className="spinner">
// 	  <div className="bounce1"></div>
// 	  <div className="bounce2"></div>
// 	  <div className="bounce3"></div>
// 	</div>
// );



// Homecare Ends


// IPD EMR URLS (START)
export const REACT_APP_NAME = "OPD_EMR";
export const REACT_APP_QB_MODE = "development";
export const REACT_APP_OPD_APP_TYPE = "mhvcmarketplace";
export const REACT_APP_VONAGE_INCOMING_CALL_SOCKET = "https://call.vonage.myhealthcare.co/";
export const REACT_APP_PUBNUB_PUBLISH_KEY = "pub-c-20533dba-07f2-4e57-9015-784869d00066";
export const REACT_APP_PUBNUB_SUBSCRIBE_KEY = "sub-c-806eacae-f330-11ea-afa2-4287c4b9a283";
export const NOTIFICATION_TYPE = "updateable";
export const NOTIFICATION_DURATION = 5;
// IPD EMR URLS (END)
// >>>>>>> 05aa115551d698a2e7e1220b9d023fdc4ca5763
const userData = authenticationService.currentUserValue;
export let timezoneOffsetMinutes = userData !== null ? (userData.timeZoneMinutes || 0) > 0 ? userData.timeZoneMinutes : 330 : 0;
export let enteredBy = userData !== null ? userData.userId : 0;
export let facilityId = userData !== null ? userData.facilityId : 0;
export let clinicMandatory = userData !== null ? userData.clinicMandatory : "";
export let enterpriseId = userData !== null ? userData.enterpriseId : 0;
export let enterpriseName = userData !== null ? userData.enterpriseName : 0;
export let enteredDate = new Date();
export let modifiedBy = userData !== null ? userData.userId : 0;
export let modifiedDate = new Date();
export let decimalPlace = userData !== null ? userData.decimal_places : 0;
export let enteredByName = userData !== null ? userData.employeeData !== null ? userData.employeeData.employeeName : "" : "";
export let titleName = userData !== null ? userData.employeeData !== null ? userData.employeeData.titleName : "" : "";
export let employeeCode = userData !== null ? userData.employeeData !== null ? userData.employeeData.employee_code : "" : "";
export let facilityCode = userData !== null ? userData.facility_code : "";
export let financialYearId = 1;
export let employeeData = userData !== null ? userData.employeeData : null;
export let employeeImage = userData !== null ? userData.employeeData.employeeImage : "";
export let employeeImageSize = 500000;   // 500kb
export let employeeSignatureSize = 100000;   //100kb
export let facilityName = userData !== null ? userData.facilityName : "";
export let facilityImage = userData !== null ? userData.facilityImage : "";
export let faIntegrationCode = userData !== null ? userData.faIntegrationCode : "";
export const facilityAddress = userData !== null ? userData.facilityAddress : "";
export const contactNo = userData !== null ? userData.contactNo : "";
export const emergencyContactNo = userData !== null ? userData.emergencyContactNo : "";
export let roleName = userData !== null ? userData.roleName : "";
export let roleId = userData !== null ? userData.roleId : 0;
export let defaultLandingModulePage = userData !== null ? userData.defaultLandingModulePage : "";
export let facilityStateGSTCode = userData !== null ? userData.stateGSTCode !== null ? userData.stateGSTCode : "06" : "06";
export let GSTApplicable = true;
export let facilityGSTINNo = userData !== null ? userData.facility_gst_number : "";
export let userSubGroupCode = userData !== null ? userData.employeeData !== null ? userData.employeeData.userSubGroupCode : "" : "";
export let cbuFacilityId = userData !== null ? userData.cbuFacilityId !== null ? userData.cbuFacilityId : 1 : 1;
export let otDefaultSlot = 60;
export let defaultRegPageId = 9;
export let emptyMessageInAgGrid = '<span class="ag-overlay-loading-center d-none"></span>';
export let emptyMessageForHTMLTable = "";
export let LoadingTemplate = "<span class='ag-overlay-loading-center border-0'><img src='https://myhealthcare-his.s3.ap-south-1.amazonaws.com/Test/Assets/images/loader.gif' width='70' /></span>";
export let Loader = "https://myhealthcare-his.s3.ap-south-1.amazonaws.com/Test/Assets/images/loader.gif"
export let userGroup = userData !== null ? userData.employeeData !== null ? userData.employeeData.userGroup : "" : "";
export let userGroupName = userData !== null ? userData.employeeData !== null ? userData.employeeData.userGroupName : "" : "";
export const statusTypeList = MasterDataService.currentStatusType.subscribe();
export const paramterSteupList = MasterDataService.parameterSetupData.subscribe();
export const statusTypeListTemp = MasterDataService.dataService.getStatusTypeData();
export const paramterSteupListTemp = MasterDataService.dataService.getParameterSetupData();
export const toastDelayTime = 3500;
export const userLANIP = userData !== null ? userData.userLANIP : '';
export const userWANIP = userData !== null ? userData.userWANIP : '';
export const userOSName = userData !== null ? userData.userOSName : '';
export const userBrowserName = userData !== null ? userData.userBrowserName : '';
export const integrationid = userData !== null ? userData.integrationid : '0';
export const networkPrinterAddress = "http://10.10.95.175/api/printPdf";//http://localhost:3007/api/printPdf
export let compLogo = <img src={userData !== null ? userData.facilityImage : ""} alt="" style={{ maxWidth: '100%' }} />
export let UserData = userData;
export let printAccountDetail = userData !== null ? userData.printAccountDetail : "";
export let cin_number = userData !== null ? userData.cin_number : "";
export let facility_legal_name = userData !== null ? userData.facility_legal_name : "";
export let facility_emailid = userData !== null ? userData.facility_emailid : "";
export let accountName = userData !== null ? userData.accountName : "";
export let accountNumber = userData !== null ? userData.accountNumber : "";
export let ifscCode = userData !== null ? userData.ifscCode : "";
export let micrCode = userData !== null ? userData.micrCode : "";
export let accountType = userData !== null ? userData.accountType : "";
export let bankName = userData !== null ? userData.bankName : "";
export let branchName = userData !== null ? userData.branchName : "";
export let branchAddress = userData !== null ? userData.branchAddress : "";
//export const QmsAddress ='https://mhcdev.api.myhealthcare.co/api/';
export let exportToCSVRecordLimit = 50000;
export const sessionTimeoutInMinutes = userData !== null ? userData.sessionTimeoutInMinutes : 15;